import React, { Fragment } from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Button from '@components/Button';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import StoryTellingSlider from '@components/StoryTellingSlider';
import EmployeeCard from '@components/EmployeeCard';
import ContactCTA from '@components/ContactCTA';

const About = ({
    data: {
        page: {
            frontmatter: { title, description, employees, sliderItems, values, heroGraphic },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`About`} />
            <div className={styles.aboutWrapper}>
                <Header
                    title={title}
                    description={description}
                    addButton
                    graphic={heroGraphic}
                    layout="about"
                />

                <div className={styles.heroCardsContainer}>
                    <h3>Our Team</h3>
                    <Divider margin={2} />
                    {/*<Divider margin={4} />*/}
                    <Grid>
                        <Row center="xs">
                            {employees?.map(({ name, position, description, image }, index) => (
                                <Col xl={4} lg={4} md={5} sm={6} xs={12} key={name}>
                                    <EmployeeCard
                                        name={name}
                                        position={position.toUpperCase()}
                                        image={image}
                                        bio={description}
                                    />
                                    {!index && <Divider xsMargin={4} smMargin={4} />}
                                </Col>
                            ))}
                        </Row>
                    </Grid>
                </div>

                <Divider margin={4} />
                <Divider margin={4} />

                <div className={styles.valuesSectionContainer}>
                    <div className={styles.valuesHeading} id={'values'}>
                        <h3>Our Values</h3>
                    </div>
                    <Divider margin={4} />
                    <Divider xsMargin={0} smMargin={0} mdMargin={4} />
                    <div className={styles.valuesContainer}>
                        <Grid>
                            {values?.map(({ image, text, title }, index) => (
                                <Fragment key={title}>
                                    {!!index && (
                                        <Fragment>
                                            <Divider margin={4} />
                                            <Divider xsMargin={0} smMargin={0} mdMargin={4} />
                                        </Fragment>
                                    )}
                                    <Row end={index % 2 !== 0 && 'xs'} key={title} gutters={false}>
                                        <Col
                                            xl={3}
                                            lg={3}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                            last={index % 2 !== 0 && 'md'}
                                            shrink
                                        >
                                            <div
                                                className={`${styles.valuesImageContainer} ${
                                                    index % 2 !== 0 ? styles.valuesRightImage : ''
                                                }`}
                                            >
                                                {/*<Img*/}
                                                {/*    fluid={image?.childImageSharp?.fluid}*/}
                                                {/*    alt={title}*/}
                                                {/*/>*/}
                                                <img src={image?.publicURL} alt={title} />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={7} sm={12} xs={12}>
                                            {/*<Divider xsMargin={4} smMargin={4} mdMargin={0} />*/}
                                            <Divider xsMargin={4} smMargin={4} mdMargin={0} />
                                            <div
                                                className={`${styles.valuesTextContainer} ${
                                                    index % 2 !== 0 ? styles.valuesRightText : ''
                                                }`}
                                            >
                                                <h3>{title}</h3>
                                                <p>{text}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Fragment>
                            ))}
                        </Grid>
                    </div>
                </div>

                <Divider margin={4} />
                <Divider margin={4} />

                <div className={styles.downloadButton}>
                    <a href={'/LiveYou-Manifesto.pdf'}>
                        <Button text="View our Manifesto PDF" />
                    </a>
                </div>

                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />

                <StoryTellingSlider storyTelling={sliderItems} />

                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />

                <ContactCTA />
            </div>
        </Layout>
    );
};

export default About;

export const query = graphql`
    query aboutPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: about_title
                description: about_description
                heroGraphic: about_us_hero_graphic {
                    publicURL
                }
                employees: about_employees {
                    name: about_employees_employee_name
                    id: about_employees_employee_id
                    position: about_employees_employee_position
                    description: about_employees_employee_description
                    image: about_employees_employee_image {
                        childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                values: about_values {
                    title: about_values_value_title
                    text: about_values_value_text
                    image: about_values_value_image {
                        publicURL
                    }
                }
                sliderItems: about_from_here_items {
                    title: about_from_here_items_item_title
                    description: about_from_here_items_item_description
                    images: about_from_here_items_item_images {
                        childImageSharp {
                            fluid(maxWidth: 750) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    overviewImage: about_from_here_items_item_overview_image {
                        childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
