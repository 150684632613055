import React, { Fragment, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import Badge from '@components/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const StoryTellingSlider = ({ storyTelling, stories }) => {
    const [slickSlider, setSlickSlider] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        infinite: false,
        arrows: true,
        dots: true,
        useTransform: true,
        cssEase: 'ease-in-out',
        afterChange: e => {
            setActiveIndex(e);
        },
    };

    const next = () => {
        slickSlider?.slickNext();
    };
    const previous = () => {
        slickSlider?.slickPrev();
    };

    const handleSlide = index => {
        slickSlider.slickGoTo(index);
    };

    return (
        <div className={styles.accommodationsSlider}>
            <Grid noMargin>
                <Row>
                    <Col xs={12}>
                        <div className={styles.sliderWrapper}>
                            <Slider
                                {...settings}
                                ref={e => {
                                    if (e) setSlickSlider(e);
                                }}
                            >
                                {storyTelling?.map(
                                    (
                                        {
                                            title,
                                            overviewImage,
                                            description,
                                            image,
                                            images,
                                            ceremonies: ceremonyIds,
                                        },
                                        index
                                    ) => {
                                        const filteredStories = stories;
                                        return (
                                            <div key={index} className={styles.imagesWrapper}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12} md={8}>
                                                                <div
                                                                    className={styles.topLeftImage}
                                                                >
                                                                    <Img
                                                                        fluid={
                                                                            images[0]
                                                                                ?.childImageSharp
                                                                                ?.fluid
                                                                        }
                                                                        style={{ height: '345px' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={styles.topRightImage}
                                                                >
                                                                    <Img
                                                                        fluid={
                                                                            images?.[1]
                                                                                ?.childImageSharp
                                                                                ?.fluid
                                                                        }
                                                                        style={{ height: '345px' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Divider margin={3} />
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.bottomLeftImage
                                                                    }
                                                                >
                                                                    <Img
                                                                        fluid={
                                                                            images?.[2]
                                                                                ?.childImageSharp
                                                                                ?.fluid
                                                                        }
                                                                        style={{ height: '345px' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.sliderItemContentWrapper
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.sliderItemContent
                                                                        }
                                                                    >
                                                                        <h3>{title}</h3>
                                                                        {!!filteredStories && (
                                                                            <Fragment>
                                                                                {filteredStories?.map(
                                                                                    ({
                                                                                        id,
                                                                                        title,
                                                                                    }) => (
                                                                                        <Badge
                                                                                            key={id}
                                                                                            text={
                                                                                                title
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                )}
                                                                                <Divider
                                                                                    margin={1}
                                                                                />
                                                                            </Fragment>
                                                                        )}
                                                                        <p>{description}</p>
                                                                        <Divider margin={3} />
                                                                        {!!activeIndex ? (
                                                                            <div
                                                                                className={
                                                                                    styles.arrowWrapper
                                                                                }
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={[
                                                                                        'far',
                                                                                        'long-arrow-left',
                                                                                    ]}
                                                                                    className={
                                                                                        styles.arrowPrev
                                                                                    }
                                                                                    onClick={
                                                                                        previous
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={
                                                                                    styles.arrowWrapper
                                                                                }
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={[
                                                                                        'far',
                                                                                        'long-arrow-right',
                                                                                    ]}
                                                                                    className={
                                                                                        styles.arrowNext
                                                                                    }
                                                                                    onClick={next}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div
                                                                        className={
                                                                            styles.sliderItemButton
                                                                        }
                                                                    ></div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <div
                                                                    className={
                                                                        styles.bottomRightImage
                                                                    }
                                                                >
                                                                    <Img
                                                                        fluid={
                                                                            images?.[3]
                                                                                ?.childImageSharp
                                                                                ?.fluid
                                                                        }
                                                                        style={{ height: '345px' }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }
                                )}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default StoryTellingSlider;
